.date .p-inputtext {
  padding: 0.5rem 0.5rem !important;
  border-radius: 0 !important;
}
.date .p-dropdown {
  border-radius: 0 !important;
}
.datepik input {
  border: 1px solid !important;
  padding: 0.5rem 0.5rem !important;
  border-radius: 0 !important;
  width: 100% !important;
}

