* {
    font-family: 'Poppins', sans-serif;
    /*font-family: 'Roboto', sans-serif;*/
}

iframe {
    width: 100%;
}
.p-inputtext {
    padding: 0.5rem 0.75rem !important;
}
/* Input Type Date Icon */
.date-input::-webkit-calendar-picker-indicator {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="black" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
}

.dark .date-input::-webkit-calendar-picker-indicator {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="white" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
}

.date-input::-webkit-inner-spin-button{
    background-color: red !important;
}
html.dark {
    background-color: rgb(55, 65, 81) !important;
}
.dark select option{
    background: rgb(55, 65, 81);
    color: white !important;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}
.p-card .p-card-body {
    padding: 1rem;
    margin: 5px
}
.bottom-dropdown{
    border: none !important;
    background: #ffffff;
    border-bottom: 1px solid #ced4da !important;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
     border-radius: 0 !important;
}

.p-card .p-card-body .p-card-content {
    padding: 0.75rem;
    margin: 0;
}

.p-card-title {
    text-align: left;
}

.p-timeline.p-timeline-vertical .p-timeline-event-opposite, .p-timeline.p-timeline-vertical .p-timeline-event-content {
    padding: 0 1rem;
}

.p-timeline-event-separator {
    flex: 0 1;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.btn-bg {
    background-color: #6366F1;
}

table tr th span {
    white-space: nowrap;
}

table tr td {
    white-space: nowrap;
}

.focus-input {
    border: none;
}

.focus-input:focus {
    outline: 0;
    border-bottom: 1px dashed rgba(6, 6, 240, 0.74);
}

.zero button {
    padding: 0 !important;
    background: transparent !important;
    color: black !important;
    border: none !important;
    width: unset !important;
}

/*.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {*/
/*    background: rgb(22, 171, 230) !important;*/
/*    color: white !important;*/
/*    border: 0 none !important;*/
/*}*/

/*.p-paginator .p-paginator-pages .p-paginator-page {*/
/*    background-color: transparent;*/
/*    border: 0 none !important;*/
/*    min-width: 2rem !important;*/
/*    height: 2rem !important;*/
/*    transition: box-shadow 0.2s;*/
/*    border-radius: 2px !important;*/
/*}*/

/* .radious {
    position: relative;
}
.radious:after {
    content: '';
    position: absolute;
    background: #ffffff;
    height: 50px;
    width: 50px;
    top: 100%;
    right: 0;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 93% 65%, 87% 50%, 78% 36%, 66% 25%, 51% 15%, 36% 9%, 20% 4%);
}
.radious::before {
    content: '';
    position: absolute;
    background: #ffffff;
    height: 50px;
    width: 50px;
    bottom: 100%;
    right: 0;
    rotate: 90deg;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 93% 65%, 87% 50%, 78% 36%, 66% 25%, 51% 15%, 36% 9%, 20% 4%);
} */
.dark .p-datatable .p-datatable-thead > tr > th {
    background: rgb(17 24 39) !important;
    border: 0 none !important;
    color: white !important;
}

.dark .p-datatable .p-datatable-header {
    background: rgb(31 41 55) !important;
}

.dark .p-datatable .p-datatable-header {
    background: rgb(31 41 55) !important;
    border: 0 none !important;
}

.dark .p-datatable .p-datatable-tbody > tr {
    background: rgb(31 41 55) !important;
}

/*.dark .p-paginator {*/
/*    background: rgb(31 41 55) !important;*/
/*    color: white;*/
/*}*/

.dark .p-datatable .p-sortable-column .p-sortable-column-icon {
    color: white;
}

/*.dark .p-datatable .p-datatable-tbody > tr > td {*/
/*    border: 0 none !important;*/
/*}*/

.dark .p-dropdown-label {
    border: 0 none !important;
}

/*.dark .p-datatable .p-paginator-bottom {*/
/*    border-width: 0 !important;*/
/*    border-radius: 0;*/
/*}*/

/*.dark .p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last {*/
/*    background-color: transparent;*/

/*    color: white !important;*/

/*}*/

@media (max-width: 575px) {
    .radious::before {
        display: none;
    }

    .radious::after {
        display: none;
    }
}

@media screen and (max-width: 991px) {
    .customized-timeline .p-timeline-event-opposite {
        display: none;

    }

    .p-card .p-card-body {
        margin: 5px
    }

    .p-timeline-event-separator {
        display: none;
    }

    .p-timeline .p-timeline-event-marker {
        display: none;
    }

    .p-timeline.p-timeline-vertical .p-timeline-event-connector {
        display: none;
    }

    .customized-timeline .p-timeline-event:nth-child(2n) .p-timeline-event-content {
        text-align: left !important;
    }

    .prime_time_line .p-card-body {
        padding: 5px !important;
    }
}

::-webkit-scrollbar {
    display: none;
}