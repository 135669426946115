.loader-18 {
  position: relative;
}

.loader-18:before,
.loader-18:after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid transparent;
  border-bottom-color: #0acccc;
  top: 0;
  left: 0;
  animation: loader-18 linear infinite 1s;
}


.loader-18:before {
  width: 50px;
  height: 50px;
}

.loader-18:after {
  width: 30px;
  height: 30px;
  top: 10px;
  left: 10px;
  animation-direction: reverse;
}

@keyframes loader-18 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
